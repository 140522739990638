/* Basic editor styles */
.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  ul {
    list-style-type: disc;
    padding-left: 1.5rem; // Adjust padding as needed
    margin-bottom: 1rem; // Adjust margin as needed
  
    li {
      margin-bottom: 0.5rem; // Adjust margin as needed
    }
  }
  
  ol {
    list-style-type: decimal;
    padding-left: 1.5rem; // Adjust padding as needed
    margin-bottom: 1rem; // Adjust margin as needed
  
    li {
      margin-bottom: 0.5rem; // Adjust margin as needed
    }
  }  

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  h1 { 
    font-size: 2.25rem; 
    font-weight: 700;
  }
  h2 { 
    font-size: 1.75rem; 
    font-weight: 600;
  }
  h3 { 
    font-size: 1.5rem; 
    font-weight: 500;
  }
  h4 { 
    font-size: 1.25rem; 
    font-weight: 500;
  }
  h5 { font-size: 1rem; }
  h6 { font-size: 0.875rem; }


  code {
    background-color: rgba(#616161, 0.2);
    color: rgb(24, 24, 27);
    font-size: 13px;
    font-weight: 600;
    border-radius: 0.25rem;
    padding: 2px 4px;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    border-radius: 0.5rem;
    line-height: 18px;

    code {
      overflow-y: scroll;
      word-wrap: normal;
      color: inherit;
      padding: 10px;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}

#sidebar-content {
  pre {
    padding: .7rem;
    line-height: 13px;
    width: auto;
    min-width: max-content;

    code {
      word-wrap: normal;
      line-height: 12px;
      font-size: 0.7rem;
      padding: 0;
    }
  }
}

.ProseMirror {
  pre {
    white-space: revert;
    overflow-x: scroll;
  }
}


@import url('@radix-ui/colors/tomato-dark.css');
@import url('@radix-ui/colors/mauve-dark.css');

.dark-editor {
  --accentBase: var(--tomato-1);
  --accentBgSubtle: var(--tomato-2);
  --accentBg: var(--tomato-3);
  --accentBgHover: var(--tomato-4);
  --accentBgActive: var(--tomato-5);
  --accentLine: var(--tomato-6);
  --accentBorder: var(--tomato-7);
  --accentBorderHover: var(--tomato-8);
  --accentSolid: var(--tomato-9);
  --accentSolidHover: var(--tomato-10);
  --accentText: var(--tomato-11);
  --accentTextContrast: var(--tomato-12);

  --baseBase: var(--mauve-1);
  --baseBgSubtle: var(--mauve-2);
  --baseBg: var(--mauve-3);
  --baseBgHover: var(--mauve-4);
  --baseBgActive: var(--mauve-5);
  --baseLine: var(--mauve-6);
  --baseBorder: var(--mauve-7);
  --baseBorderHover: var(--mauve-8);
  --baseSolid: var(--mauve-9);
  --baseSolidHover: var(--mauve-10);
  --baseText: var(--mauve-11);
  --baseTextContrast: var(--mauve-12);

  --admonitionTipBg: var(--cyan4);
  --admonitionTipBorder: var(--cyan8);
  
  --admonitionInfoBg: var(--grass4);
  --admonitionInfoBorder: var(--grass8);

  --admonitionCautionBg: var(--amber4);
  --admonitionCautionBorder: var(--amber8);

  --admonitionDangerBg: var(--red4);
  --admonitionDangerBorder: var(--red8);

  --admonitionNoteBg: var(--mauve-4);
  --admonitionNoteBorder: var(--mauve-8);

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --font-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

  color: var(--baseText);
  --basePageBg: black;
  background: var(--basePageBg);
}