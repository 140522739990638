@tailwind base;
@tailwind components;
@tailwind utilities;

  @layer base {
    :root {
      --background: 0 0% 100%;
      --foreground: 240 10% 3.9%;

      --card: 0 0% 100%;
      --card-foreground: 240 10% 3.9%;

      --popover: 0 0% 100%;
      --popover-foreground: 240 10% 3.9%;

      --primary: 240 5.9% 10%;
      --primary-foreground: 0 0% 98%;

      --secondary: 240 4.8% 95.9%;
      --secondary-foreground: 240 5.9% 10%;

      --muted: 240 4.8% 95.9%;
      --muted-foreground: 240 3.8% 46.1%;

      --accent: 240 4.8% 95.9%;
      --accent-foreground: 240 5.9% 10%;

      --destructive: 0 84.2% 60.2%;
      --destructive-foreground: 0 0% 98%;

      --border: 240 5.9% 90%;
      --input: 240 5.9% 90%;
      --ring: 240 10% 3.9%;

      --radius: 0.5rem;

      --sidebar-background: 0 0% 98%;

      --sidebar-foreground: 240 5.3% 26.1%;

      --sidebar-primary: 240 5.9% 10%;

      --sidebar-primary-foreground: 0 0% 98%;

      --sidebar-accent: 240 4.8% 95.9%;

      --sidebar-accent-foreground: 240 5.9% 10%;

      --sidebar-border: 220 13% 91%;

      --sidebar-ring: 217.2 91.2% 59.8%;
    }

    .dark {
      --background: 240 10% 3.9%;
      --foreground: 0 0% 98%;

      --card: 240 10% 3.9%;
      --card-foreground: 0 0% 98%;

      --popover: 240 10% 3.9%;
      --popover-foreground: 0 0% 98%;

      --primary: 0 0% 98%;
      --primary-foreground: 240 5.9% 10%;

      --secondary: 240 3.7% 15.9%;
      --secondary-foreground: 0 0% 98%;

      --muted: 240 3.7% 15.9%;
      --muted-foreground: 240 5% 64.9%;

      --accent: 240 3.7% 15.9%;
      --accent-foreground: 0 0% 98%;

      --destructive: 0 62.8% 30.6%;
      --destructive-foreground: 0 0% 98%;

      --border: 240 3.7% 15.9%;
      --input: 240 3.7% 15.9%;
      --ring: 240 4.9% 83.9%;
      --sidebar-background: 240 5.9% 10%;
      --sidebar-foreground: 240 4.8% 95.9%;
      --sidebar-primary: 224.3 76.3% 48%;
      --sidebar-primary-foreground: 0 0% 100%;
      --sidebar-accent: 240 3.7% 15.9%;
      --sidebar-accent-foreground: 240 4.8% 95.9%;
      --sidebar-border: 240 3.7% 15.9%;
      --sidebar-ring: 217.2 91.2% 59.8%;
    }
  }

  @layer base {
    * {
      @apply border-border;
    }
    body {
      @apply bg-background text-foreground;
    }
  }


  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  
  .react-flow__node-default, .react-flow__node-input, .react-flow__node-output, .react-flow__node-group {
    border: none !important;
  }
  
  .floatingedges {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 100%;
  }
  
  .floatingedges .react-flow__handle {
    opacity: 0;
  }

  .hide-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }
  
  .hide-scrollbar::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  

div .react-flow__attribution {
  display: none;
}